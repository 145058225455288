<template>
  <section
    class="overflow-hidden w-full h-full relative"
    @contextmenu="setContextIdWidget"
  >
    <div class="pb-2 flex justify-end" style="padding-right: 30px;" v-if="showInfo">
      <div>
        <input
          type="number"
          min="0"
          class="fields border-0 font-color"
          placeholder="Enter no. of columns"
          :value="numberOfColumnsToShow"
          @change.prevent.stop="
            changedNumberOfColumns('numberOfColumnsToShow', $event.target.value)
          "
        />
      </div>
      <div v-if="numberOfColumnsToShow" class="flex items-center ml-2" style="gap: 10px; font-size: 12px">
        <button
          @click.prevent.stop="
            addRow(Object.keys(rowsList)[Object.keys(rowsList).length - 1])
          "
          style="color: green"
          title="Add row"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          v-if="Object.keys(rowsList).length > 1"
          @click.prevent.stop="
            deleteRow(Object.keys(rowsList)[Object.keys(rowsList).length - 1])
          "
          style="color: red"
          title="Delete row"
        >
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
    <button class="toggleBtn" @click="toggleTableDetails">
      <!-- <i class="fa fa-eye" aria-hidden="true"></i> -->
      <i class="fas fa-info-circle"></i>
    </button>
    <div class="tableContainer" :style="{height: `calc(100% - ${showInfo ? 42 : 0}px)`}">
      <table v-if="numberOfColumnsToShow" class="w-full h-full" ref="tableRef">
        <thead class="theadClass sticky top-0">
          <tr @mousemove.prevent.stop="resizeMouseMove($event, resizeElementIndex)" @mouseup.prevent.stop="toRemoveResize">
            <!-- <th class="text-center px-2">Action</th> -->
            <th v-for="(header, index) in headerNames" :key="index" class="relative" :colspan="header.colspan" v-show="colSpansCheck(index)">
              <input
                type="text"
                class="headerFields border-0 px-2"
                :value="header.title"
                placeholder="Enter header name"
                @change.prevent.stop="
                  changedColumnName('headerNames', index, $event.target.value)
                "
              />
              <span class="resize-handle" v-if="headerNames.length > 1" @mousedown.prevent.stop="resizeMouseDown($event, index)" @mouseup.prevent.stop="toRemoveResize"></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rect, objKey, rowIndex) in rowsList" :key="rowIndex">
            <!-- <td class="actionButtonsTd">
              <div v-if="Object.keys(rowsList).length-1 == rowIndex" class="h-full w-full flex justify-center items-center px-2" style="gap: 10px;">
                  <button @click.prevent.stop="addRow(objKey)" style="color: green;"><i class="fas fa-plus"></i></button>
                  <button v-if="rowIndex !== 0" @click.prevent.stop="deleteRow(objKey)" style="color: red;"><i class="fas fa-trash"></i></button>
              </div>
            </td> -->
            <td v-for="(header, columnIndex) in headerNames" :key="columnIndex">
              <numeric-widget
                v-if="rect[columnIndex] && rect[columnIndex].name == 'numeric'"
                :key="columnIndex"
                :id="rect[columnIndex].widgetId"
                :displayId="rect[columnIndex].displayId"
                :widgetId="rect[columnIndex].widgetId"
                :isChange="rect[columnIndex].isChange"
                :widgetHeight="rect[columnIndex].height"
                :widgetWidth="rect[columnIndex].width"
                :borderDisplay="rect[columnIndex].borderDisplay"
                :borderWidth="rect[columnIndex].borderWidth"
                :borderColor="rect[columnIndex].borderColor"
                :alertStartRange="rect[columnIndex].alertStartRange"
                :alertEndRange="rect[columnIndex].alertEndRange"
                :alertColo="rect[columnIndex].alertColor"
                :blinkAlert="rect[columnIndex].blinkAlert"
                :cardBackground="rect[columnIndex].cardBackground"
                :autoFit="rect[columnIndex].autoFit"
                :titleDisplay="rect[columnIndex].titleDisplay"
                :titleSize="rect[columnIndex].titleSize"
                :titleColor="rect[columnIndex].titleColor"
                :titleBgColor="rect[columnIndex].titleBgColor"
                :title="rect[columnIndex].title"
                :fullName="rect[columnIndex].fullName"
                :valueDisplay="rect[columnIndex].valueDisplay"
                :valueSize="rect[columnIndex].valueSize"
                :valueColor="rect[columnIndex].valueColor"
                :valueBgColor="rect[columnIndex].valueBgColor"
                :decimalValue="rect[columnIndex].decimalValue"
                :value="rect[columnIndex].value"
                :unitBgColor="rect[columnIndex].unitBgColor"
                :unitDisplay="rect[columnIndex].unitDisplay"
                :unitSize="rect[columnIndex].unitSize"
                :unitColor="rect[columnIndex].unitColor"
                :widgetUnitL="rect[columnIndex].unit"
                :isDragStarted="false"
                :isReplay="rect[columnIndex].isReplay"
                :unit_conversion_factor="
                  rect[columnIndex].unit_conversion_factor
                "
                v-bind:class="[
                  isRightOpen || $store.state.uis.leftSideBarOpen
                    ? 'widge_resp_width'
                    : 'w-100',
                  'labelNumeric_NumericWidget',
                ]"
                @dblclick.prevent.stop="
                  $emit('openProperty', columnIndex, rect[columnIndex])
                "
              ></numeric-widget>
              <div v-else class="flex justify-center">
                <button
                  @click.prevent.stop="
                    addNumericIndividuallyInCell(Number(objKey), columnIndex)
                  "
                  style="color: green"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue";
export default {
  props: {
    displayId: {
      type: String,
      default: null,
    },
    widgetId: {
      type: String,
      default: null,
    },
    numericWidgetData: {
      type: Object,
      default: null,
    },
    backgroundId: {
      type: [String, Number],
      default: "",
    },
    widgetWidth: {
      type: [String, Number],
      default: 0,
    },
    widgetHeight: {
      type: [String, Number],
      default: 0,
    },
    parentH: {
      type: [String, Number],
      default: 0,
    },
    topPosition: {
      type: [String, Number],
      default: 0,
    },
  },
  components: {
    NumericWidget: defineAsyncComponent(() =>
      import("../Numeric/NumericWidget.vue")
    ),
  },
  data() {
    return {
      showInfo: false,
      isResizing: false,
      initialXPos: null,
      resizeElementIndex: null,
    };
  },
  methods: {
    colSpansCheck(currentIndex){
      try{
        let sumOfPrevHeadsColspans = 0;
        for(let i = 0; i < currentIndex; i++){
          sumOfPrevHeadsColspans += Number(this.headerNames[i].colspan) || 0;
        }
        if(sumOfPrevHeadsColspans >= this.headerNames.length){
          return false;
        }
        else{
          return true;
        }
      }
      catch(err){
        return true;
      }
    },
    resizeMouseDown(event, resizeElementIndex){
      this.isResizing = true;
      this.initialXPos = event.clientX;
      this.resizeElementIndex = resizeElementIndex;
    },
    resizeMouseMove(event, index){
      if(this.isResizing){
        let headers = JSON.parse(JSON.stringify(this.headerNames));
        let currentElement = headers[index];
        let currenColspan = currentElement.colspan || null;
        // MOVING TO THE LEFT SIDE 
        if(event.movementX < 0 && currenColspan > 1 && (this.initialXPos - event.clientX) > 30){
          currenColspan--;
          currentElement.colspan = currenColspan;
          this.initialXPos = event.clientX;
          this.commonDispatcher("headerNames", headers);
        }

        // MOVING TO THE RIGHT SIDE 
        if(event.movementX > 0 && currenColspan < headers.length && (event.clientX - this.initialXPos) > 30){
          currenColspan++;
          currentElement.colspan = currenColspan;
          this.initialXPos = event.clientX;
          this.commonDispatcher("headerNames", headers);
        }
      }
    },
    toRemoveResize(){
      this.isResizing = false;
    },
    toggleTableDetails() {
      this.showInfo = !this.showInfo;
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    commonDispatcher(keyToDispatch, val) {
      this.$store.dispatch("rect/swabSurgeRealTimedata", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: val,
        field: keyToDispatch,
      });
    },
    changedNumberOfColumns(keyToDispatch, val) {
      this.commonDispatcher(keyToDispatch, +val);
    },
    changedColumnName(keyToDispatch, indexToChange, val) {
      let headers = JSON.parse(JSON.stringify(this.headerNames));
      headers[indexToChange].title = val;
      this.commonDispatcher(keyToDispatch, headers);
    },
    addNumeric(rowNum, colNum) {
      let numericData = JSON.parse(JSON.stringify(this.numericWidgetData));
      numericData.widgetId = "bg" + Math.random().toString(16).slice(2);
      numericData.bgId = this.backgroundId;
      numericData.displayId = this.displayId;
      numericData.rowNumber = rowNum;
      numericData.colNumber = colNum;
      this.$store.dispatch("rect/addNewRect", {
        x: 100,
        y: 100,
        elProps: numericData,
      });
    },
    addRow(currentObjKey) {
      for (let i = 0; i < this.headerNames.length; i++) {
        this.addNumeric(Number(currentObjKey) + 1, i);
      }
      let heightOfWidget = Math.round(this.$refs?.tableRef.getBoundingClientRect().height + 42 + 35) // 42 - add and delete toggle section height, 35 - newly adding row height
      if(this.widgetHeight < heightOfWidget && heightOfWidget < (Number(this.parentH) - this.topPosition - 40)){  // 40 - well selction bar (adminsubnavbar) height
        this.commonDispatcher('height', heightOfWidget)
      }
    },
    deleteRow(currentObjKey) {
      let arrayToDeleteNumerics = this.$store.state.rect.rects.filter(
        (rect, index) => {
          if (
            rect.displayId == this.displayId &&
            rect.bgId == this.backgroundId &&
            Number(rect.rowNumber) == Number(currentObjKey)
          ) {
            return rect;
          }
        }
      );
      arrayToDeleteNumerics.map((rect) => {
        this.$store.dispatch("rect/deleteWidget", {
          displayId: this.displayId,
          widgetId: rect.widgetId,
        });
      });
    },
    addNumericIndividuallyInCell(rowNum, colNum) {
      this.addNumeric(rowNum, colNum);
    },
  },
  computed: {
    numberOfColumnsToShow() {
      return (
        this.$store.state.rect?.rects[this.rectIndex]?.numberOfColumnsToShow ||
        null
      );
    },
    rectIndex() {
      let index = this.$store.state.rect?.rects.findIndex(
        (rect) =>
          rect.displayId == this.displayId && rect.widgetId == this.widgetId
      );
      return index >= 0 ? index : null;
    },
    headerNames() {
      return this.$store.state.rect?.rects[this.rectIndex]?.headerNames || [];
    },
    rowsList() {
      let allRects = this.$store.state.rect?.rects;
      let filteredRects = allRects.reduce((obj, rect) => {
        if (
          rect.displayId == this.displayId &&
          rect.bgId == this.backgroundId
        ) {
          let rowNumberKey = rect.rowNumber.toString();
          let colNumberKey = rect.colNumber.toString();
          if (!obj.hasOwnProperty(rowNumberKey)) obj[rowNumberKey] = {};
          obj[rowNumberKey] = {
            ...obj[rowNumberKey],
            [colNumberKey]: rect,
          };
        }
        return obj;
      }, {});
      return filteredRects;
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (
        mutation.type == "rect/swabSurgeRealTimedata" &&
        this.displayId == mutation.payload.displayId &&
        this.widgetId == mutation.payload.widgetId &&
        mutation.payload.field == "numberOfColumnsToShow"
      ) {
        let cols = mutation.payload.value;
        let headers = JSON.parse(JSON.stringify(this.headerNames));
        if (cols > 0 && cols > headers.length) {
          let diff = cols - headers.length;
          let maxCount = headers.length + Number(diff);

          // Adding Numeric widgets
          for (let rowNumb in this.rowsList) {
            for (let colNum = headers.length; colNum < maxCount; colNum++) {
              this.addNumeric(rowNumb, colNum);
            }
          }

          // Adding headers
          for (let i = 0; i < diff; i++) {
            headers.push({title: '', colspan: 1});
          }
          this.commonDispatcher("headerNames", headers);
        } else if (cols > 0 && cols < headers.length) {
          let diff = headers.length - cols;

          // Deleting Numeric widgetes
          for (let rowNumb in this.rowsList) {
            let eachRowKeys = Object.keys(this.rowsList[rowNumb]);
            let keysToDelete = eachRowKeys.slice(-diff);
            keysToDelete.map((eachKey) => {
              let rectToDelete = this.rowsList[rowNumb][eachKey];
              this.$store.dispatch("rect/deleteWidget", {
                displayId: rectToDelete.displayId,
                widgetId: rectToDelete.widgetId,
              });
            });
          }

          // Deleting headers
          headers.splice(headers.length - diff);
          this.commonDispatcher("headerNames", headers);
        } else {
          this.commonDispatcher("headerNames", []);
        }
        if (
          !this.rowsList ||
          (this.rowsList && !Object.keys(this.rowsList).length)
        ) {
          for (let i = 0; i < headers.length; i++) {
            this.addNumeric(1, i);
          }
        }
      }
      document.addEventListener('mouseup', this.toRemoveResize);
    });

    // SHOWING 4 * 4 TABLE BY DEFAULT
    if(!this.numberOfColumnsToShow){ 
      this.commonDispatcher('numberOfColumnsToShow', 4);

      // Adding Numeric widgets
      for (let rowNumb = 0; rowNumb < 4; rowNumb++) {
        for (let colNum = 0; colNum < 4; colNum++) {
          this.addNumeric(rowNumb, colNum);
        }
      }
    }
  },
  beforeUnmount(){
    document.removeEventListener('mouseup', this.toRemoveResize);
  },
};
</script>
<style scoped>
.fields {
  height: 30px;
  width: 100%;
  margin-top: 4px !important;
  color: var(--sidebarListColor) !important;
  background-color: var(--root_nav) !important;
  line-height: 1.4;
}
.headerFields {
  height: 30px;
  background-color: transparent !important;
  line-height: 1.4;
  /* font-weight: bold; */
  width: 100%;
  text-align: center;
  letter-spacing: 0.025em;
}
.headerFields::placeholder {
  font-size: 11px;
}
/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
table {
  background-color: var(--root_nav);
}
th {
  border-style: solid;
  border-color: var(--root_nav);
  border-width: 2px 2px 0px 2px;
}
td {
  border: 2px solid var(--root_nav);
}
.tableContainer {
  height: calc(100% - 42px);
  overflow: auto;
}
.theadClass {
  /* background-color: var(--nav1BgClr); */
  color: var(--activeTextColor);
  font-size: 14px;
  /* background-color: var(--root_nav); */
  background-color: var(--navBar2Bg);
  z-index: 1;
  box-shadow: 0px 0px 2px var(--root_nav);
  top: -2px;
}
.labelNumeric_NumericWidget {
  width: 100% !important;
  /* background: transparent !important; */
  box-shadow: none;
  height: 100% !important;
}
.actionButtonsTd {
  display: table-cell;
  background: var(--navBar2Bg);
  font-size: 12px;
}
.toggleBtn {
  position: absolute;
  top: 0px;
  right: 1px;
  font-size: 11px;
  z-index: 2;
}
.resize-handle {
  width: 4px;
  height: 100%;
  /* background-color: var(--textColor); */
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  cursor: ew-resize;
  opacity: 0.3;
}
</style>